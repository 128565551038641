:root {
    --main-color: #0000ff;
    --main-color-alpha: #0000ffaa;
}

.react-photo-gallery--gallery img {
    object-fit: cover;
    background-color: #ffffff;
}

@font-face {
    font-family: pcd_regular;
    src: url(fonts/PCD22-Regular.ttf);
}

@font-face {
    font-family: n_haas_roman;
    src: url(fonts/NHaasGroteskTXPro-55Rg.ttf);
}
@font-face {
    font-family: n_haas_medium;
    src: url(fonts/NHaasGroteskTXPro-65Md.ttf);
}
@font-face {
    font-family: n_haas_bold;
    src: url(fonts/NHaasGroteskTXPro-75Bd.ttf);
}



div{
    font-family: n_haas_roman;
    color: black;/*var(--main-color);*/
}

div a, div a:hover, .sublinhado, .sublinhado:hover{
    font-family: n_haas_roman;
    color: var(--main-color);
}

.sublinhado, .sublinhado:hover{
    text-decoration: underline;
    cursor: pointer;
}

#navbar, #navbar .navbar-brand, #navbar div a{
    background: var(--main-color);
    font-size: 2.5rem;
    padding: 0.1em 0.4em 0.1em 0.4em;
    font-family: pcd_regular;
}

.nav-link{
    font-size: 1.5rem;
}


#footer .about_call_menu{
    width: 50%;
    text-align: center;
    color:#ffffff;
    outline: 0px solid white;
    object-fit: fill;
    padding: 1rem;
    font-size: 1.7em;
    font-family: pcd_regular;
}

.about_call_menu:first-child{
    border-right: 3px solid white;
}

.about_call_menu:last-child{
    border-left: 3px solid white;
}

#footer{
    background: var(--main-color);
    padding: 0;
}

.offcanvas.about_call_div{
    width: 50%;
    background: white;/*#F2F2F2;*/
    border-width: 0px 3px 0px 3px;
    border-color: var(--main-color);
}

.offcanvas.exhibition_div{
    width: 100%;
    background: white;/*#F2F2F2;*/
    border-width: 0px 3px 0px 3px;
    border-color: var(--main-color);
}

.title_div{/*about and call title*/
    font-size: 2.8em;
    font-family: pcd_regular;
}

.sub_title_div {
    text-transform: uppercase;
    font-weight: 900;
    font-family: pcd_regular;
    letter-spacing: 1.7px;
    font-size: 1.8rem;
    margin-bottom: 0.5em;
}

.sub_topic {
    margin-top: 2.5em;

}

.sub_sub_title_div {
    margin-top: 1.5em;
    text-transform: uppercase;
    font-weight: 900;
    font-family: n_haas_medium;
    letter-spacing: 1.7px;
    font-size: 1.1rem;
    color: var(--main-color);
}


.offcanvas .offcanvas-body, .offcanvas .offcanvas-header {
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 1.4em;
}

.offcanvas .offcanvas-header{
    margin-top: 1.8rem;
    padding-bottom: 0;

}

.offcanvas .offcanvas-body {
    padding-top: 0rem;
    padding-bottom: 3rem;
}

.btn-close, .btn-close:hover{
    opacity: 1;
    color: var(--main-color) !important;
}

#comModules, #openSubs{
    column-count: 3;
}
#comModules span, #openSubs span{
    color: var(--main-color);
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

p.no-m {
    margin-bottom: 0;
}

.text_bold{
    font-family: n_haas_medium;
}

.text_bold_color{
    font-family: n_haas_medium;
    color: var(--main-color);
}

.text_enf{
    /*color: var(--main-color);*/
    font-style: italic;
}
.text_enf_color{
    color: var(--main-color);
    font-family: n_haas_medium;
}

li{
    list-style-type: none;
}

.justify-content-center {
    justify-content: space-between !important;
}

.poster{
    position: relative;
    width: 32%;
    margin-right: 5px;
    margin-top: 2.5px;
    display: inline-block;
}

.posterDetails{
    position: absolute;
    /* background-color: rgba(255,0,0, var(--alpha)); */
    background-color: var(--main-color-alpha);

    top: 0;
    width:100%;
    height:100%;
    text-align: left;
    color: white;
    padding: 3rem;
}

.posterDetails :first-child{
    color: white;
    margin-top: 0;
    margin-bottom: 0.25rem;
    font-size:1.5rem;

}

video{
    width: 100vw;
    position: absolute;
    left: 0;

}

.posterDetails :last-child{
    font-size: 1.5rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #navbar, #navbar .navbar-brand, #navbar div a{
        font-size: 1.5rem;
    }
    #comModules, #openSubs{
        column-count: 1;
    }

    .poster{
        width: 100%;
    }

    .navbar-brand span {
        display: none;
    }
    .navbar-brand:after {
        content: 'PCD2022';
    }

    #footer .about_call_menu {
        font-size: 1.0rem;
    }

    .offcanvas.about_call_div {
        width: 100%;
    }
}

